import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getModulos } from "../api/getModulos";

export function ModulesDropdown({ cursoId, onChange }) {
  const [modulos, setModulos] = useState([]);
  const [fetchingModulos, setFetchingModulos] = useState(false);

  useEffect(() => {
    if (cursoId) {
      async function fetchModulos() {
        setFetchingModulos(true);
        try {
          const modulosObtidos = await getModulos(cursoId);
          setModulos(modulosObtidos);
        } catch (error) {
          console.error("Erro ao carregar módulos:", error);
        }
        setFetchingModulos(false);
      }

      fetchModulos();
    }
  }, [cursoId]);

  return (
    <div>
      {cursoId && (
        <div>
          <h5 className="mt-3 mb-3">Selecione um Módulo</h5>
          <Row>
            <Col md={6} lg={4}>
              <Form.Group controlId="moduloSelect" className="mb-3">
                <Form.Select size="l" onChange={onChange}>
                  {fetchingModulos ? (
                    <option>Carregando...</option>
                  ) : (
                    <>
                      <option value="">Selecione um módulo...</option>
                      {modulos.map((modulo) => (
                        <option key={modulo.id} value={modulo.id}>
                          {modulo.title.rendered}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
