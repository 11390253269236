import api from "../service";

export const getMeetings = async () => {
  const response = await api.get("api/gotomeeting/meetings", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};
