import api from "../service";

export const getCursos = async () => {
  const response = await api.get("api/wordpress/cursos", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};
