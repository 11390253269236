import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { HomeScreen } from "./homescreen/index.js";
import Login from "./login/index.js";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomeScreen />} />
      </Routes>
    </>
  );
}

export default App;
