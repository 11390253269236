

export const getAuthenticate = (setAuthenticated) => {
  const authWindow = window.open(
    `${process.env.REACT_APP_API_URL}/api/gotomeeting/authorize`,
    "AuthWindow",
    "width=800,height=600"
  );

  const handleAuthMessage = (event) => {
    if (event.data === "authenticated") {
      authWindow.close();
      setAuthenticated(true);
    }
  };

  window.addEventListener("message", handleAuthMessage);

  return () => {
    window.removeEventListener("message", handleAuthMessage);
  };
};
