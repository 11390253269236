import api from "../service";

export async function uploadVideoApi(meeting) {
  const body = {
    subject: meeting.subject,
    fileSize: meeting.recording.fileSize,
    videoUrl: meeting.recording.downloadUrl,
  };

  await api.post("/api/vimeo/upload", body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
