import React from "react";
import { Alert, Button } from "react-bootstrap";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { createClass } from "../api/createClass.js";
import { ActionsButton } from "../components/actions-button.js";
import BaseScreen from "../components/base-screen.js";
import { CourseModuleSection } from "../components/curse-module-section.js";
import { Header } from "../components/header.js";

export function HomeScreen() {
  const [message, setMessage] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [concludedUpload, setConcludedUpload] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [concludedDownload, setConcludedDownload] = React.useState(false);
  const [selectedPairs, setSelectedPairs] = React.useState([
    { curso: null, modulo: null },
  ]);
  const navigate = useNavigate();

  function handleCursoChange(event, index) {
    const updatedPairs = [...selectedPairs];
    updatedPairs[index] = { ...updatedPairs[index], curso: event.target.value };
    setSelectedPairs(updatedPairs);
  }

  function handleModuloChange(event, index) {
    const updatedPairs = [...selectedPairs];
    updatedPairs[index] = {
      ...updatedPairs[index],
      modulo: event.target.value,
    };
    setSelectedPairs(updatedPairs);
  }

  async function onCreateClass() {
    setIsLoading(true);
    const promises = selectedPairs.map((pair) =>
      createClass(pair.curso, pair.modulo)
    );

    try {
      const results = await Promise.allSettled(promises);
      const success = results.filter(
        (result) => result.status === "fulfilled"
      ).length;
      setMessage(`${success} aulas criadas com sucesso!`);
    } catch (error) {
      setMessage("Erro ao criar aulas!");
      setHasError(true);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        resetState();
      }, 4000);
    }
  }

  function addSelection() {
    setSelectedPairs([...selectedPairs, { curso: null, modulo: null }]);
  }

  function removeItem() {
    const updatedPairs = [...selectedPairs];
    updatedPairs.pop();
    setSelectedPairs(updatedPairs);
  }

  function resetState() {
    setMessage("");
    setHasError(false);
    setIsLoading(false);
    setConcludedDownload(false);
    setConcludedUpload(false);
    setAuthenticated(false);
    setSelectedPairs([]);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  function ExitButton() {
    return (
      <Button 
      variant="danger" 
      onClick={handleLogout} 
      className="ms-auto" 
    >
        Sair <FontAwesomeIcon icon={faSignOutAlt} />
      </Button>
    );
  }

  return (
    <BaseScreen>
      <div>
        <Header />
        <ActionsButton
          setMessage={setMessage}
          setHasError={setHasError}
          concludedUpload={concludedUpload}
          setConcludedUpload={setConcludedUpload}
          authenticated={authenticated}
          setAuthenticated={setAuthenticated}
          concludedDownload={concludedDownload}
          setConcludedDownload={setConcludedDownload}
        />
        {concludedUpload && (
          <CourseModuleSection
            selectedPairs={selectedPairs}
            handleCursoChange={handleCursoChange}
            handleModuloChange={handleModuloChange}
            addSelection={addSelection}
            onCreateClass={onCreateClass}
            isLoading={isLoading}
            concludedUpload={concludedUpload}
            removeItem={removeItem}
          />
        )}
        <div className="d-flex justify-content-end">
          <ExitButton />
        </div>
      </div>
      {message && (
        <Alert className="mt-3" variant={hasError ? "danger" : "success"}>
          {message}
        </Alert>
      )}
    </BaseScreen>
  );
}
