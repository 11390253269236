import api from "../service";

export const getModulos = async (cursoId) => {
  const response = await api.get(`api/wordpress/cursos/${cursoId}/modulos`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};
