import React, { useEffect } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { getAuthenticate } from "../api/autenticar";
import { uploadVideoApi } from "../api/uploadVideo";
import { getMeetings } from "../api/getMeetings";

export const ActionsButton = ({
  setMessage,
  setHasError,
  concludedUpload,
  setConcludedUpload,
  authenticated,
  setAuthenticated,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [meeting, setMeeting] = React.useState({});
  const [fetchingMeeting, setFetchingMeeting] = React.useState(false);
  const [meetingOptions, setMeetingOptions] = React.useState([]);

  useEffect(() => {
    async function fetchMeetings() {
      setFetchingMeeting(true);
      const meetings = await getMeetings();
      setMeetingOptions(meetings);
      setFetchingMeeting(false);
    }
    if (authenticated) {
      fetchMeetings();
    }
  }, [authenticated]);

  function handleAuthenticate() {
    getAuthenticate(setAuthenticated);
  }

  function clearMessage() {
    setMessage("");
    setHasError(false);
  }

  async function handleUpload() {
    setIsLoading(true);
    try {
      await uploadVideoApi(meeting);
      setMessage("Download e Upload concluídos com sucesso!");
      setConcludedUpload(true);
    } catch (error) {
      setHasError(true);
      setMessage("Erro ao fazer upload do vídeo " + error.message);
    } finally {
      setIsLoading(false);
      setTimeout(clearMessage, 3000);
    }
  }

  function getButonMessage() {
    if (authenticated) {
      return "Autenticado";
    }

    return "Autenticar";
  }

  function renderButtonUploadSelectedContent() {
    if (isLoading && meeting) {
      return <div className="spinner-border" role="status"></div>;
    }

    if (concludedUpload) {
      return "Aula Enviada";
    }

    return "Enviar Aula Selecionada";
  }

  function AuthenticateButtonComponent() {
    return (
      <div className="mb-5">
        <Row className="align-items-center mb-3">
          <Col>
            <h3>Realizar autenticação no GoToMeeting</h3>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col md={3} lg={1}>
            <Button variant="primary" onClick={handleAuthenticate}>
              {getButonMessage()}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  const handleChange = (e) => {
    const selectedMeeting = JSON.parse(e.target.value);
    setMeeting(selectedMeeting);
  };

  function UploadButtonComponent() {
    return (
      <div className="mb-5">
        <Row className="align-items-center mb-3">
          <Col>
            <h3>Subir Gravação para o Vimeo</h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6} lg={4} className="mb-3">
            <Form.Group>
              <Form.Select
                value={JSON.stringify(meeting)}
                onChange={handleChange}
                disabled={isLoading}
              >
                {fetchingMeeting ? (
                  <option>Carregando...</option>
                ) : (
                  <>
                    <option value="">
                      {!authenticated
                        ? "Aguardando Autenticação..."
                        : "Selecione uma aula..."}
                    </option>
                    {meetingOptions.map((meeting) => (
                      <option
                        key={meeting.meetingId}
                        value={JSON.stringify(meeting)}
                      >
                        {meeting.subject}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col className="align-items-center mb-3">
            <Button
              variant={authenticated ? "success" : "secondary"}
              onClick={handleUpload}
              disabled={!authenticated || !meeting}
            >
              {renderButtonUploadSelectedContent()}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <AuthenticateButtonComponent />
      <UploadButtonComponent />
    </>
  );
};
